@import 'variables';

.bg {

  @each $color-key, $color-value in $color {

    &-#{$color-key} {
      background: #{$color-value}
    }
  }
}
