@import 'variables';

.t {

  &-a {

    &-l {
      text-align: left;
    }

    &-c {
      text-align: center;
    }

    &-r {
      text-align:  right;
    }
  }

  &-s {

    &-xxs {
      font-size: map-get($size, xxsTextSize);
      line-height: map-get($size, xxsLineHeight);
    }

    &-xs {
      font-size: map-get($size, xsTextSize);
      line-height: map-get($size, xsLineHeight);
    }

    &-sm {
      font-size: map-get($size, smTextSize);
      line-height: map-get($size, smLineHeight);
    }

    &-md {
      font-size: map-get($size, mdTextSize);
      line-height: map-get($size, mdLineHeight);
    }

    &-lg {
      font-size: map-get($size, lgTextSize);
      line-height: map-get($size, lgLineHeight);
    }

    &-xl {
      font-size: map-get($size, xlTextSize);
      line-height: map-get($size, xlLineHeight);
    }

    &-xxl {
      font-size: map-get($size, xxlTextSize);
      line-height: map-get($size, xxlLineHeight);
    }
  }

  &-w {

    @each $weight-key, $weight-value in $weight {
      &-#{$weight-value} {
        font-weight: #{$weight-value};
      };
    }
  }

  &-c {

    @each $color-key, $color-value in $color {
      &-#{$color-key} {
        color: #{$color-value};

        &--h:hover {
          color: #{$color-value}
        }
      }
    }
  }

  &-o {

    &-e {
      text-overflow: ellipsis;
    }
  }

  &-t {

    &-c {
      text-transform: capitalize;
    }
  }

  &-ws {

    &-nw {
      white-space: nowrap;
    }
  }

  &-ww {

    &-ba {
      word-break: break-all;
    }
  }
}
