aside {
  box-shadow: 2px 0 8px rgba(48, 54, 70, 0.06);
  max-height: calc(100vh - 75px);
  overflow-y: auto;

  .nav {

    &__unit {
      display: flex;
      align-items: center;
      border-left: 4px solid transparent;
      text-decoration: none;
      color: var(--base700);
      padding: 12px 0;
      text-indent: 10px;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &--level {

        &-1 {
          padding-left: 25px;
        }

        &-2 {
          padding-left: 50px;
        }

        &-3 {
          padding-left: 75px;
        }

        &-4 {
          padding-left: 100px;
        }
      }

      &.active {
        background: var(--primary200);
        color: var(--primary700);
        border-left-color: var(--primary700);
      }

      &:hover {
        background: var(--base200);
        color: var(--primary800);
      }
    }

    &__content {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }

  footer {
    margin-top: auto;
    border-top: 1px solid var(--base400);
    padding: 16px 24px;
  }
}
