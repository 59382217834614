$sidebarWidth: 320px;

main {
  min-height: calc(100vh - 75px);

  aside {
    width: $sidebarWidth;
  }

  section {
    width: calc(100% - #{$sidebarWidth});
  }
}
