@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');
@import './scss/variables.scss';

:root {
  @each $color-key, $color-value in $color {
    --#{$color-key}: #{$color-value};
  }
}

@import './scss/reset';
@import './scss/position';
@import './scss/display';
@import './scss/flex';
@import './scss/text';
@import './scss/indent';
@import './scss/bg';
@import './scss/size';
@import './scss/other';
@import './scss/permission';
@import './scss/tooltip';
@import './scss/table';
@import './scss/datepicker';
@import './scss/inputs';
