.table-cell {
    white-space: normal;
    word-break: break-word;
}

.table-container {
  overflow-x: auto;
}

.fixed-column {
  background-color: white;
  border-left: 1px solid #eeeeee;
  width: 200px;
}

.table-wrapper {
  width: 100%;
}

.fixed-column-header {
  background-color: #eeeeee;
  text-align: right;
}

.border-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
}

.no-wrap {
  white-space: nowrap;
}

.pagination-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
