.jsoneditor {

  .jsoneditor-outer {
    height: 500px;
  }

  .jsoneditor-poweredBy {
    display: none;
  }
}
