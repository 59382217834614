.non-perm {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(map-get($color, 'base100'), 0.5);
  }

  &:hover {
    color: inherit;
    cursor: default;
  }
}
