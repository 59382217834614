$preloader: (
  xs: 32px,
  sm: 64px,
  md: 128px,
  lg: 254px
);

.preloader {
  position: relative;

  @each $preloader-key, $preloader-value in $preloader {
    &__#{$preloader-key} {
      width: $preloader-value;
      height: $preloader-value;
    };
  }

  path {
    $duration: 0.5s;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: $duration ease-in-out 0s infinite alternate top;
      }
    }
    @for $i from 4 through 6 {
      &:nth-child(#{$i}) {
        animation: $duration ease-in-out 0s infinite alternate bottom;
      }
    }
  }

  @keyframes top {

    50% {
      transform: translateY(-5%);
    }
  }

  @keyframes bottom {

    50% {
      transform: translateY(5%);
    }
  }
}
