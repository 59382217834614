$map: (
  b: 'block',
  ib: 'inline-block',
  f: 'flex',
  if: 'inline-flex',
  n: 'none'
);

.d {

  @each $key, $value in $map {
    &-#{$key} {
      display: #{$value};
    }
  }
}
