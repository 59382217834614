@import 'variables';

$direction: (
  t: 'top',
  r: 'right',
  b: 'bottom',
  l: 'left'
);


@each $indent-key, $indent-value in $indent {

  @each $direction-key, $direction-value in $direction {

    .p-#{$direction-key}-#{$indent-key} {
      padding-#{$direction-value}: #{$indent-value};
    }

    .m-#{$direction-key}-#{$indent-key} {
      margin-#{$direction-value}: #{$indent-value};
    }
  }
}

.p {

  @each $indent-key, $indent-value in $indent {

    &-a-#{$indent-key} {
      padding: #{$indent-value};
    }

    &-x-#{$indent-key} {
      padding-right: #{$indent-value};
      padding-left: #{$indent-value};
    }

    &-y-#{$indent-key} {
      padding-top: #{$indent-value};
      padding-bottom: #{$indent-value};
    }
  }
}

.m {

  @each $indent-key, $indent-value in $indent {

    &-a-#{$indent-key} {
      margin: #{$indent-value};
    }

    &-x-#{$indent-key} {
      margin-right: #{$indent-value};
      margin-left: #{$indent-value};
    }

    &-y-#{$indent-key} {
      margin-top: #{$indent-value};
      margin-bottom: #{$indent-value};
    }
  }

  @each $direction-key, $direction-value in $direction {

    &-#{$direction-key}-auto {
      margin-#{$direction-value}: auto;
    }
  }
}
