.tooltip {
  position: relative;

  &__trigger {
    cursor: pointer;
  }

  &__content {
    display: none;
    position: absolute;
    z-index: 20;
    white-space: nowrap;
    background: rgba(map-get($color, 'base900'), 1);
    color: map-get($color, 'base100');
    padding: 8px 12px;
    border-radius: 4px;

    &--top-right {
      bottom: 100%;
      right: 0;
    }

    &--bottom-right {
      top: 100%;
      right: 0;
    }

    &--bottom-left {
      top: 100%;
      left: 0;
    }
  }
}

.tooltip__trigger:hover + .tooltip__content {
  display: block;
}
