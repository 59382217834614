.f {
  display: flex;

  &-d {

    &-c {
      flex-direction: column;
    }

    &-r {
      flex-direction: row;
    }
  }

  &-ai {

    &-s {
      align-items: start;
    }

    &-c {
      align-items: center;
    }

    &-e {
      align-items: end;
    }
  }

  &-jc {

    &-s {
      justify-content: flex-start;
    }

    &-c {
      justify-content: center;
    }

    &-e {
      justify-content: flex-end;
    }

    &-sb {
      justify-content: space-between;
    }
  }
}
