$positions: (
  a: 'absolute',
  r: 'relative',
  s: 'static'
);

.p {

  @each $key, $value in $positions {
    &-#{$key} {
      position: #{$value};
    }
  }
}

.z {

  &-11 {
    z-index: 11;
  }
}
