.o {

  &-c {

    &-p {
      cursor: pointer;
    }
  }

  &-ws {

    &-n {
      white-space: nowrap;
    }
  }

  &-o {

    &-h {
      overflow: hidden;
    }
  }
}
