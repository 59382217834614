* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--primary200);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  fill: transparent;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.no-class {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  fill: transparent;
}

.h-full {
    height: 100%;
}

.Mui-disabled {
  /* color: rgba(0, 0, 0, 0.26); */
  /* box-shadow: none; */
  /* background-color: rgba(0, 0, 0, 0.12); */
}